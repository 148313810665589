import Grid from '@mui/material/Grid2'
import { AccountSchema } from '../model'
import { Typography, Box, Popover, Link } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import moment from 'moment'
import { formatAmount } from 'shared/lib/formatAmount'
import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { documentModel } from 'entities/document'
import { paymentModel } from 'entities/payment'

interface AccountProps {
  data: AccountSchema
}
export const Account = ({ data }: AccountProps) => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { docsStatusFilter } = documentModel.useDocsState()

  const { setDocsPeriodFilter, setDocsStatusFilter, setDocsStartPeriodDate } =
    documentModel.useFiltersActions()

  const {
    setPaymentsPeriodFilter,
    setPaymentsStatusFilter,
    setPaymentsStartPeriodDate,
  } = paymentModel.useFiltersActions()

  const onMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSumClick = (split: AccountSchema['costsSplit'][0]) => {
    if (split.isRko) {
      navigate(generatePath(RoutePath.document, { documentid: split.id }))
    } else navigate(generatePath(RoutePath.payment, { paymentid: split.id }))
  }

  const onDateClick = (split: AccountSchema['costsSplit'][0]) => {
    if (split.isRko) {
      if (docsStatusFilter !== 'Разрешено') {
        setDocsStatusFilter('Разрешено')
      }
      setDocsStartPeriodDate(
        moment(split.date).add(-1, 'month').format('YYYY-MM-DD')
      )
      setDocsPeriodFilter({
        start: split.date,
        end: null,
      })
      navigate(generatePath(RoutePath.main))
    } else {
      setPaymentsStatusFilter(null)
      setPaymentsStartPeriodDate(
        moment(split.date).add(-1, 'month').format('YYYY-MM-DD')
      )
      setPaymentsPeriodFilter({
        start: split.date,
        end: null,
      })
      navigate(generatePath(RoutePath.payments))
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Grid container rowSpacing={0} size={12} color="primary">
      <Grid size={!!data.costs ? 11 : 12}>
        <Typography fontSize={18}>{data.name}</Typography>
      </Grid>
      {!!data.costs && (
        <Grid size={1}>
          <Box display="flex" alignItems="end" justifyContent="end">
            <Link
              height="27px"
              component="button"
              variant="subtitle2"
              color="info"
              onClick={onMoreClick}
            >
              <InfoIcon />
            </Link>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box p={2}>
              <Typography fontSize={18}>{data.name}</Typography>
              <Box sx={{ maxHeight: 280, overflow: 'auto' }} mt={1}>
                <Typography
                  fontSize={16}
                  color={data.costs ? 'success' : 'primary'}
                  display="inline"
                >
                  {formatAmount(data.balance / 100, {
                    currency: 'RUB',
                    withZero: true,
                  })}
                </Typography>{' '}
                -{' '}
                {data.costsSplit.map(({ id, sum, date }, splitIndex, arr) => (
                  <Box>
                    <Link
                      component="button"
                      variant="button"
                      color="error"
                      sx={{ fontSize: 16, lineHeight: 1.3 }}
                      onClick={() => onSumClick(arr[splitIndex])}
                    >
                      {formatAmount(sum / 100, {
                        currency: 'RUB',
                        withZero: true,
                      })}
                    </Link>
                    (
                    <Link
                      component="button"
                      variant="button"
                      color="textSecondary"
                      sx={{ fontSize: 16, lineHeight: 1.3 }}
                      onClick={() => onDateClick(arr[splitIndex])}
                    >
                      {moment(date).format('DD.MM')}
                    </Link>
                    ){splitIndex !== arr.length - 1 && <> - </>}
                  </Box>
                ))}{' '}
                ={' '}
                <Typography fontSize={16} color="primary" display="inline">
                  {formatAmount((data.balance - data.costs) / 100, {
                    currency: 'RUB',
                    withZero: true,
                  })}
                </Typography>
              </Box>
            </Box>
          </Popover>
        </Grid>
      )}
      <Grid size={12}>
        <Typography
          fontSize={16}
          color={data.costs ? 'success' : 'primary'}
          display="inline"
        >
          {formatAmount(data.balance / 100, {
            currency: 'RUB',
            withZero: true,
          })}
        </Typography>
        {!!data.costs && (
          <>
            {' '}
            -{' '}
            <Typography fontSize={16} color="error" display="inline">
              {formatAmount(data.costs / 100, {
                currency: 'RUB',
                withZero: true,
              })}
            </Typography>{' '}
            ={' '}
            <Typography fontSize={16} color="primary" display="inline">
              {formatAmount((data.balance - data.costs) / 100, {
                currency: 'RUB',
                withZero: true,
              })}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  )
}
