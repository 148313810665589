import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from 'shared/api/customFetchBaseQuery'
import { SERVER_URL } from 'shared/configs/serverConfig'
import { DocumentSchema } from './document.types'
import { CalendarObjectSchema } from 'shared/types/common'

export const api = createApi({
  reducerPath: 'document-api',
  tagTypes: ['DOCUMENTS', 'DOCUMENT'],
  baseQuery: customBaseQuery({ baseUrl: `${SERVER_URL}/documents` }),
  endpoints: (builder) => ({
    documentsByPeriod: builder.query<
      CalendarObjectSchema<DocumentSchema>,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `/period?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
      providesTags: ['DOCUMENTS'],
      onQueryStarted: async (arg, api) => {
        try {
          const { data } = await api.queryFulfilled
          api.dispatch({ type: 'document-slice/setData', payload: data })
        } catch (error) {
          console.error(error)
        }
      },
    }),

    document: builder.query<DocumentSchema, string>({
      query: (documentId) => ({
        url: `/document/${documentId}`,
        method: 'GET',
      }),
      providesTags: ['DOCUMENT'],
    }),

    approveDocument: builder.mutation<
      DocumentSchema,
      Pick<DocumentSchema, 'guid' | 'confirmed'> & { force?: number }
    >({
      query: ({ guid, confirmed, force }) => ({
        url: `/approve/${guid}?force=${force ?? 0}`,
        method: 'PUT',
        body: { confirmed },
      }),
      invalidatesTags: ['DOCUMENTS', 'DOCUMENT'],
    }),

    approveSomeDocuments: builder.mutation<
      unknown,
      { body: DocumentSchema[]; force?: number }
    >({
      query: ({ body, force }) => ({
        url: `/approve_docs/some?force=${force ?? 0}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['DOCUMENTS'],
    }),
  }),
})

export const {
  useDocumentsByPeriodQuery,
  useDocumentQuery,
  useApproveDocumentMutation,
  useApproveSomeDocumentsMutation
} = api
