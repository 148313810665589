import { createHashRouter } from 'react-router-dom'
import App from 'app/App'
import { AccountsPage } from 'pages/AccountsPage'
// import { ExpensesPage } from 'pages/ExpensesPage'
import { LoginPage } from 'pages/LoginPage'
import { LogoutPage } from 'pages/LogoutPage'
import { MainPage } from 'pages/MainPage'
import { NotFoundPage } from 'pages/NotFoundPage'
import { VerifyPage } from 'pages/VerifyPage'
import { NavbarWrapper } from 'widgets/Navbar'
import { DocumentPage } from 'pages/DocumentPage'
import { SettingsPage } from 'pages/SettingsPage'
import { ApproveDocumenProvider } from 'features/document/approve'
import { ApproveSomeDocumentsProvider } from 'features/document/approve-some'
import { Detector } from 'react-detect-offline'
import { ApprovePaymentProvider } from 'features/payment/approve'
import { PaymentsPage } from 'pages/PaymentsPage'
import { PaymentPage } from 'pages/PaymentPage'

export enum AppRoutes {
  MAIN = 'main',
  DOCUMENT = 'document',
  PAYMENT = 'payment',
  PAYMENTS = 'payments',
  ACCOUNTS = 'accounts',
  EXPENSES = 'expenses',
  SETTINGS = 'settings',
  LOGIN = 'login',
  VERIFY = 'verify',
  LOGOUT = 'logout',
  NOT_FOUND = 'not_found',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: `/`,
  [AppRoutes.DOCUMENT]: `/${AppRoutes.DOCUMENT}/:documentid`,
  [AppRoutes.PAYMENTS]: `/${AppRoutes.PAYMENTS}`,
  [AppRoutes.PAYMENT]: `/${AppRoutes.PAYMENT}/:paymentid`,
  [AppRoutes.ACCOUNTS]: `/${AppRoutes.ACCOUNTS}`,
  [AppRoutes.EXPENSES]: `/${AppRoutes.EXPENSES}`,
  [AppRoutes.SETTINGS]: `/${AppRoutes.SETTINGS}`,
  [AppRoutes.LOGOUT]: `/${AppRoutes.LOGOUT}`,
  [AppRoutes.LOGIN]: `/${AppRoutes.LOGIN}`,
  [AppRoutes.VERIFY]: `/${AppRoutes.VERIFY}`,
  [AppRoutes.NOT_FOUND]: '*',
}

export const router = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <NavbarWrapper />,
        children: [
          {
            path: RoutePath.main,
            element: (
              <Detector
                render={({ online }) => (
                  <ApproveDocumenProvider>
                    <ApproveSomeDocumentsProvider>
                      <MainPage isOnline={online} />
                    </ApproveSomeDocumentsProvider>
                  </ApproveDocumenProvider>
                )}
              />
            ),
          },
          {
            path: RoutePath.payments,
            element: (
              <Detector
                render={({ online }) => (
                  <ApprovePaymentProvider>
                    <PaymentsPage isOnline={online} />
                  </ApprovePaymentProvider>
                )}
              />
            ),
          },
          {
            path: RoutePath.accounts,
            element: (
              <Detector
                render={({ online }) => <AccountsPage isOnline={online} />}
              />
            ),
          },
          // {
          //   path: RoutePath.expenses,
          //   element: <ExpensesPage />,
          // },
        ],
      },
      {
        path: RoutePath.document,
        element: (
          <Detector
            render={({ online }) => (
              <ApproveDocumenProvider>
                <DocumentPage isOnline={online} />
              </ApproveDocumenProvider>
            )}
          />
        ),
      },
      {
        path: RoutePath.payment,
        element: (
          <Detector
            render={({ online }) => (
              <ApprovePaymentProvider>
                <PaymentPage isOnline={online} />
              </ApprovePaymentProvider>
            )}
          />
        ),
      },
      {
        path: RoutePath.settings,
        element: (
          <Detector
            render={({ online }) => <SettingsPage isOnline={online} />}
          />
        ),
      },
      {
        path: RoutePath.login,
        element: (
          <Detector render={({ online }) => <LoginPage isOnline={online} />} />
        ),
      },
      {
        path: RoutePath.verify,
        element: (
          <Detector render={({ online }) => <VerifyPage isOnline={online} />} />
        ),
      },
      { path: RoutePath.logout, element: <LogoutPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
])
