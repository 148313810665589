import {
  Backdrop,
  Button,
  CircularProgress,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import DoneIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/Error'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router'
import { settingsModel } from 'entities/settings'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import ListSubheader from '@mui/material/ListSubheader'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import Popper from '@mui/material/Popper'
import { SettingsSchema } from 'entities/settings/model/settings.types'
import { IntegerField } from 'shared/ui/IntegerField/IntegerField'
import { Notification } from 'shared/ui/Notification/Notification'
import { useRootDispatch } from 'shared/hooks/redux'
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import { PageLayout } from 'shared/ui/PageLayout/PageLayout'
import { PageProps } from 'shared/types/common'
import { PageError } from 'shared/ui/PageError/PageError'
import React from 'react'
import { typedObjectEntries } from 'shared/lib/typedObjectEntries'
import grey from '@mui/material/colors/grey'

const ITEM_HEIGHT = 54
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  const { key, label, selected, ...optionProps } = dataSet[0]
  console.log(inlineStyle)
  return (
    <Typography key={key} component="li" {...optionProps} style={inlineStyle}>
      <Checkbox checked={selected} />
      {label}
    </Typography>
  )
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null)
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactElement<unknown>[] = []
  ;(children as React.ReactElement<unknown>[]).forEach(
    (
      item: React.ReactElement<unknown> & {
        children?: React.ReactElement<unknown>[]
      }
    ) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const itemCount = itemData.length
  const itemSize = 54

  const getChildSize = (child: React.ReactElement<unknown>) => {
    if (child.hasOwnProperty('group')) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

export const SettingsPage = ({ isOnline }: PageProps) => {
  const navigate = useNavigate()
  const dispatch = useRootDispatch()

  const [openSuccessNotify, setOpenSuccessNotify] = useState(false)
  const [openErrorNotify, setOpenErrorNotify] = useState(false)

  const {
    data: settings,
    isLoading,
    isError,
    refetch,
  } = settingsModel.useSettingsQuery(null, {
    skip: !isOnline,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  const [saveSettingsAction, { isLoading: saving }] =
    settingsModel.useSaveSettingsMutation()

  const onBack = () => navigate(-1)

  const { control, handleSubmit } = useForm<SettingsSchema>({
    values:
      settings &&
      typedObjectEntries(settings).reduce((result, [key, value]) => {
        const newValue = { ...value }
        Reflect.deleteProperty(newValue, 'options')
        return {
          ...result,
          [key]: newValue,
        }
      }, {} as SettingsSchema),
  })

  const saveSettings = async (values: SettingsSchema) => {
    const result = await saveSettingsAction(values)
    if ('data' in result && result.data) {
      setOpenSuccessNotify(true)
      dispatch(
        settingsModel.api.util.upsertQueryData('settings', null, result.data)
      )
    }
    if ('error' in result && result.error) {
      setOpenErrorNotify(true)
    }
  }

  const onSave = handleSubmit(saveSettings)

  return (
    <>
      <Notification
        open={openSuccessNotify}
        message="Настройки сохранены"
        icon={<DoneIcon />}
        onClose={() => setOpenSuccessNotify(false)}
      />
      <Notification
        open={openErrorNotify}
        message="Ошибка сохранения"
        icon={<ErrorIcon />}
        onClose={() => setOpenErrorNotify(false)}
      />
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={saving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout
        withNavbar
        header={
          <Stack px={2} pb={1.5} borderBottom={`1px solid ${grey[300]}`}>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              onClick={onBack}
            >
              <ArrowBackIosIcon />
              <Typography variant="h4" fontWeight="bold">
                Настройки
              </Typography>
            </Stack>
          </Stack>
        }
      >
        <PageError visible={isError} action={refetch}>
          <Stack direction="column" alignItems="center" flexGrow={1}>
            <Stack
              direction="column"
              justifyContent="center"
              gap={3}
              flexGrow={1}
            >
              <Grid container rowSpacing={2} py={1}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{
                    '& > :not(style)': { my: 1 },
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                >
                  <FormControl sx={{ width: '90vw' }}>
                    <Controller
                      name="min_doc_sum_limit.vali"
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            label="Начальная сумма РКО для согласования"
                            disabled={isLoading || isError || !isOnline}
                            helperText="для РКО с меньшей суммой не будет требоваться согласование"
                            slotProps={{
                              htmlInput: {
                                maxLength: 15,
                                pattern: /\d/,
                                inputMode: 'numeric',
                              },
                              input: {
                                inputComponent: IntegerField as any,
                                endAdornment:
                                  isLoading || isError ? undefined : (
                                    <Typography color="textSecondary">
                                      ₽
                                    </Typography>
                                  ),
                              },
                            }}
                          />
                        )
                      }}
                    />
                  </FormControl>

                  <FormControl sx={{ width: '90vw' }}>
                    <InputLabel id="allow_doc_operation-label">
                      Операции по РКО
                    </InputLabel>
                    <Controller
                      name="allow_doc_operation.valjson"
                      control={control}
                      render={({
                        field: {
                          value: fieldValue,
                          onChange: fieldOnChange,
                          ...otherFieldProps
                        },
                      }) => {
                        const value = (
                          fieldValue
                            ? fieldValue.map((item) => JSON.stringify(item))
                            : []
                        ) as string[]

                        const onChange = (
                          event: SelectChangeEvent<string[]>
                        ) => {
                          const {
                            target: { value: newValue },
                          } = event

                          fieldOnChange(
                            Array.isArray(newValue)
                              ? newValue.map((item) => JSON.parse(item))
                              : null
                          )
                        }

                        return (
                          <Select
                            {...otherFieldProps}
                            labelId="allow_doc_operation-label"
                            id="allow_doc_operation"
                            multiple
                            value={value}
                            onChange={onChange}
                            input={
                              <OutlinedInput
                                label="Операции по РКО"
                                endAdornment={
                                  fieldValue?.length ? (
                                    <InputAdornment position="start">
                                      <IconButton
                                        onClick={() => fieldOnChange(null)}
                                        edge="start"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : undefined
                                }
                              />
                            }
                            renderValue={(selected) => {
                              return selected
                                .map((item) => JSON.parse(item).value)
                                .join(', ')
                            }}
                            MenuProps={MenuProps}
                          >
                            {settings?.allow_doc_operation.options.map(
                              (option) => (
                                <MenuItem
                                  key={option.id}
                                  value={JSON.stringify(option)}
                                >
                                  <Checkbox
                                    checked={
                                      !!value.find(
                                        (item) =>
                                          JSON.parse(item).id === option.id
                                      )
                                    }
                                  />
                                  <ListItemText
                                    primary={option.value}
                                    primaryTypographyProps={{
                                      whiteSpace: 'normal',
                                    }}
                                  />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )
                      }}
                    />
                    <FormHelperText>
                      по выбранным операциям не будет требоваться согласование
                      РКО
                    </FormHelperText>
                  </FormControl>

                  {/* <FormControl sx={{ width: '90vw' }}>
                <Controller
                  name="allow_doc_operation.valjson"
                  control={control}
                  render={({
                    field: {
                      value: fieldValue,
                      onChange: fieldOnChange,
                      ...otherFieldProps
                    },
                  }) => {
                    return (
                      <Autocomplete
                        {...otherFieldProps}
                        id="allow_doc_operation"
                        limitTags={5}
                        multiple
                        disableListWrap
                        disableCloseOnSelect
                        selectOnFocus={false}
                        value={fieldValue || []}
                        disabled={isLoading || isError || !isOnline}
                        options={settings?.allow_doc_operation.options || []}
                        getOptionLabel={(option) => option.value}
                        getOptionKey={(option) => option.id}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderOption={(props, option, state) =>
                          [
                            {
                              ...props,
                              label: option.value,
                              selected: state.selected,
                            },
                            option,
                            state.index,
                          ] as React.ReactNode
                        }
                        onChange={(_, newVal) => {
                          fieldOnChange(newVal)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Операции по РКО"
                          />
                        )}
                        noOptionsText="Нет доступных значений для выбора"
                        slots={{
                          popper: StyledPopper,
                        }}
                        slotProps={{
                          listbox: {
                            component: ListboxComponent,
                          },
                          popper: {
                            placement: 'bottom',
                            modifiers: [
                              {
                                name: 'flip',
                                enabled: false,
                                options: {
                                  altBoundary: false,
                                  rootBoundary: 'viewport',
                                  padding: 8,
                                },
                              },
                              {
                                name: 'preventOverflow',
                                enabled: true,
                                options: {
                                  altAxis: true,
                                  altBoundary: true,
                                  tether: true,
                                  rootBoundary: 'viewport',
                                  padding: 8,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )
                  }}
                />
                <FormHelperText>
                  по выбранным операциям не будет требоваться согласование РКО
                </FormHelperText>
              </FormControl> */}

                  {/* <FormControl sx={{ width: '90vw' }}>
                <Controller
                  name="allow_doc_cash_article.valjson"
                  control={control}
                  render={({
                    field: {
                      value: fieldValue,
                      onChange: fieldOnChange,
                      ...otherFieldProps
                    },
                  }) => {
                    return (
                      <Autocomplete
                        {...otherFieldProps}
                        id="allow_doc_cash_article"
                        limitTags={5}
                        multiple
                        disableListWrap
                        disableCloseOnSelect
                        filterSelectedOptions
                        value={fieldValue || []}
                        disabled={isLoading || isError || !isOnline}
                        options={settings?.allow_doc_cash_article.options || []}
                        getOptionLabel={(option) => option.value}
                        getOptionKey={(option) => option.id}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderOption={(props, option, state) =>
                          [
                            {
                              ...props,
                              label: option.value,
                              selected: state.selected,
                            },
                            option,
                            state.index,
                          ] as React.ReactNode
                        }
                        onChange={(_, newVal) => {
                          fieldOnChange(newVal)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Статья расходов ДДС по РКО"
                          />
                        )}
                        noOptionsText="Нет доступных значений для выбора"
                        slots={{
                          popper: StyledPopper,
                        }}
                        slotProps={{
                          listbox: {
                            component: ListboxComponent,
                          },
                          popper: {
                            placement: 'bottom',
                            modifiers: [
                              {
                                name: 'flip',
                                enabled: false,
                                options: {
                                  altBoundary: false,
                                  rootBoundary: 'viewport',
                                  padding: 8,
                                },
                              },
                              {
                                name: 'preventOverflow',
                                enabled: true,
                                options: {
                                  altAxis: true,
                                  altBoundary: true,
                                  tether: true,
                                  rootBoundary: 'viewport',
                                  padding: 8,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )
                  }}
                />
                <FormHelperText>
                  по выбранным статьям расходов ДДС не будет требоваться
                  согласование РКО
                </FormHelperText>
              </FormControl> */}

                  <FormControl sx={{ width: '90vw' }}>
                    <InputLabel id="allow_doc_cash_article-label">
                      Статьи расходов ДДС по РКО
                    </InputLabel>
                    <Controller
                      name="allow_doc_cash_article.valjson"
                      control={control}
                      render={({
                        field: {
                          value: fieldValue,
                          onChange: fieldOnChange,
                          ...otherFieldProps
                        },
                      }) => {
                        const value = (
                          fieldValue
                            ? fieldValue.map((item) => JSON.stringify(item))
                            : []
                        ) as string[]

                        const onChange = (
                          event: SelectChangeEvent<string[]>
                        ) => {
                          const {
                            target: { value: newValue },
                          } = event

                          fieldOnChange(
                            Array.isArray(newValue)
                              ? newValue.map((item) => JSON.parse(item))
                              : null
                          )
                        }

                        return (
                          <Select
                            {...otherFieldProps}
                            labelId="allow_doc_cash_article-label"
                            id="allow_doc_cash_article"
                            multiple
                            value={value}
                            onChange={onChange}
                            input={
                              <OutlinedInput
                                label="Статьи расходов ДДС по РКО"
                                endAdornment={
                                  fieldValue?.length ? (
                                    <InputAdornment position="start">
                                      <IconButton
                                        onClick={() => fieldOnChange(null)}
                                        edge="start"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : undefined
                                }
                              />
                            }
                            renderValue={(selected) => {
                              return selected
                                .map((item) => JSON.parse(item).value)
                                .join(', ')
                            }}
                            MenuProps={MenuProps}
                          >
                            {settings?.allow_doc_cash_article.options.map(
                              (option) => (
                                <MenuItem
                                  key={option.id}
                                  value={JSON.stringify(option)}
                                >
                                  <Checkbox
                                    checked={
                                      !!value?.find(
                                        (item) =>
                                          JSON.parse(item).id === option.id
                                      )
                                    }
                                  />
                                  <ListItemText
                                    primary={option.value}
                                    primaryTypographyProps={{
                                      whiteSpace: 'normal',
                                    }}
                                  />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )
                      }}
                    />
                    <FormHelperText>
                      по выбранным статьям расходов ДДС не будет требоваться
                      согласование РКО
                    </FormHelperText>
                  </FormControl>
                  <FormControl sx={{ width: '90vw' }}>
                    <InputLabel id="allow_cost_article-label">
                      Статьи расходов/активов по РКО
                    </InputLabel>
                    <Controller
                      name="allow_cost_article.valjson"
                      control={control}
                      render={({
                        field: {
                          value: fieldValue,
                          onChange: fieldOnChange,
                          ...otherFieldProps
                        },
                      }) => {
                        const value = (
                          fieldValue
                            ? fieldValue.map((item) => JSON.stringify(item))
                            : []
                        ) as string[]

                        const onChange = (
                          event: SelectChangeEvent<string[]>
                        ) => {
                          const {
                            target: { value: newValue },
                          } = event

                          fieldOnChange(
                            Array.isArray(newValue)
                              ? newValue.map((item) => JSON.parse(item))
                              : null
                          )
                        }

                        return (
                          <Select
                            {...otherFieldProps}
                            labelId="allow_cost_article-label"
                            id="allow_cost_article"
                            multiple
                            value={value}
                            onChange={onChange}
                            input={
                              <OutlinedInput
                                label="Статьи расходов/активов по РКО"
                                endAdornment={
                                  fieldValue?.length ? (
                                    <InputAdornment position="start">
                                      <IconButton
                                        onClick={() => fieldOnChange(null)}
                                        edge="start"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : undefined
                                }
                              />
                            }
                            renderValue={(selected) => {
                              return selected
                                .map((item) => JSON.parse(item).value)
                                .join(', ')
                            }}
                            MenuProps={MenuProps}
                          >
                            {settings?.allow_cost_article.options.map(
                              (option) => (
                                <MenuItem
                                  key={option.id}
                                  value={JSON.stringify(option)}
                                >
                                  <Checkbox
                                    checked={
                                      !!value?.find(
                                        (item) =>
                                          JSON.parse(item).id === option.id
                                      )
                                    }
                                  />
                                  <ListItemText
                                    primary={option.value}
                                    primaryTypographyProps={{
                                      whiteSpace: 'normal',
                                    }}
                                  />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )
                      }}
                    />
                    <FormHelperText>
                      по выбранным статьям расходов/активов не будет требоваться
                      согласование РКО
                    </FormHelperText>
                  </FormControl>
                  <FormControl sx={{ width: '90vw' }}>
                    <InputLabel id="allow_cost_analytics-label">
                      Типы аналитики расходов по РКО
                    </InputLabel>
                    <Controller
                      name="allow_cost_analytics.valjson"
                      control={control}
                      render={({
                        field: {
                          value: fieldValue,
                          onChange: fieldOnChange,
                          ...otherFieldProps
                        },
                      }) => {
                        const value = (
                          fieldValue
                            ? fieldValue.map((item) => JSON.stringify(item))
                            : []
                        ) as string[]

                        const onChange = (
                          event: SelectChangeEvent<string[]>
                        ) => {
                          const {
                            target: { value: newValue },
                          } = event

                          fieldOnChange(
                            Array.isArray(newValue)
                              ? newValue.map((item) => JSON.parse(item))
                              : null
                          )
                        }

                        return (
                          <Select
                            {...otherFieldProps}
                            labelId="allow_cost_analytics-label"
                            id="allow_cost_analytics"
                            multiple
                            value={value}
                            onChange={onChange}
                            input={
                              <OutlinedInput
                                label="Типы аналитики расходов по РКО"
                                endAdornment={
                                  fieldValue?.length ? (
                                    <InputAdornment position="start">
                                      <IconButton
                                        onClick={() => fieldOnChange(null)}
                                        edge="start"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : undefined
                                }
                              />
                            }
                            renderValue={(selected) => {
                              return selected
                                .map((item) => JSON.parse(item).value)
                                .join(', ')
                            }}
                            MenuProps={MenuProps}
                          >
                            {settings?.allow_cost_analytics.options.map(
                              (option) => (
                                <MenuItem
                                  key={option.id}
                                  value={JSON.stringify(option)}
                                >
                                  <Checkbox
                                    checked={
                                      !!value?.find(
                                        (item) =>
                                          JSON.parse(item).id === option.id
                                      )
                                    }
                                  />
                                  <ListItemText
                                    primary={option.value}
                                    primaryTypographyProps={{
                                      whiteSpace: 'normal',
                                    }}
                                  />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )
                      }}
                    />
                    <FormHelperText>
                      по выбранным типам аналитики расходов не будет требоваться
                      согласование РКО
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
            </Stack>
          </Stack>
        </PageError>
      </PageLayout>
      {!isError && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="59px"
          borderTop={`1px solid ${grey[300]}`}
        >
          <Box>
            <Button
              size="large"
              variant="contained"
              disabled={!isOnline || isLoading}
              sx={{
                width: '285px',
                fontSize: 18,
                fontWeight: 'normal',
                textTransform: 'uppercase',
              }}
              onClick={onSave}
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
