import ErrorIcon from '@mui/icons-material/Error'
import { Modal, Box, Typography, Stack, Button } from '@mui/material'
import { documentModel } from 'entities/document'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Notification } from 'shared/ui/Notification/Notification'
import { ApproveSomeDocumentsContext } from '../lib/ApproveSomeDocumentsContext'
import { DocumentSchema } from 'entities/document/model'
import moment from 'moment'
import { formatAmount } from 'shared/lib/formatAmount'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  padding: 2,
}

interface ApproveSomeDocumentsProviderProps {
  children: ReactNode
}

interface Error {
  status: number
  data: {
    error: string
    message: Array<{
      name: string
      data: { value: string; color?: string }[]
    }>
  }
}

interface Data {
  date: string
  docs: DocumentSchema[]
}

export const ApproveSomeDocumentsProvider = ({
  children,
}: ApproveSomeDocumentsProviderProps) => {
  const approveMethods = documentModel.useApproveSomeDocumentsMutation({
    fixedCacheKey: 'approve_some_docs',
  })

  const [data, setData] = useState<Data>()

  const [approveAction, { error, originalArgs, isSuccess, reset }] =
    approveMethods

  const [openErrorModal, setOpenErrorModal] = useState(false)
  const onOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const onCloseErrorModal = () => {
    setOpenErrorModal(false)
    reset()
  }

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const onOpenConfirmModal = useCallback(
    (targetData: Data) => {
      setData(targetData)
      setOpenConfirmModal(true)
    },
    [setData, setOpenConfirmModal]
  )
  const onCloseConfirmModal = () => setOpenConfirmModal(false)

  const [openErrorNotify, setOpenErrorNotify] = useState(false)

  useEffect(() => {
    if (error && 'status' in error) {
      if (error.status === 412) onOpenErrorModal()
      else setOpenErrorNotify(true)
    }
  }, [error])

  useEffect(() => {
    if (isSuccess) {
      setData(undefined)
      reset()
    }
  }, [isSuccess, setData, reset])

  const headerErrorModal = (error as Error)?.data.error

  const messageErrorModal = (error as Error)?.data.message

  const onConfirm = async () => {
    onCloseConfirmModal()
    if (data) await approveAction({ body: data.docs })
  }

  const onOkError = async () => {
    onCloseErrorModal()
    if (data) await approveAction({ body: data.docs, force: 1 })
  }

  console.log(approveMethods[1])
  return (
    <ApproveSomeDocumentsContext.Provider
      value={{ openModal: onOpenConfirmModal }}
    >
      {children}
      <Modal
        open={openConfirmModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="span">
            Подтвердите согласование
          </Typography>
          <Box sx={{ mt: 2 }}>
            {data && (
              <Typography
                id="modal-modal-description"
                component="span"
                fontSize={18}
              >
                Вы уверены, что хотите согласовать документы за{' '}
                <Typography component="span" fontWeight="500">
                  {moment(data.date).format('DD.MM.YYYY')}
                </Typography>{' '}
                на сумму{' '}
                <Typography component="span" fontWeight="500">
                  {formatAmount(
                    data.docs.reduce((result, { sum }) => result + sum, 0) /
                      100,
                    {
                      currency: 'RUB',
                      withZero: true,
                    }
                  )}
                </Typography>
                ?
              </Typography>
            )}
          </Box>
          <Stack
            sx={{ mt: 4 }}
            spacing={2}
            direction="row"
            justifyContent="space-between"
          >
            <Box flexGrow={1}>
              <Button
                size="large"
                sx={{
                  width: '100%',
                  fontSize: 18,
                  flexGrow: 1,
                  fontWeight: 'normal',
                  textTransform: 'uppercase',
                }}
                onClick={onCloseConfirmModal}
              >
                Отмена
              </Button>
            </Box>

            <Button
              size="large"
              variant="contained"
              color="primary"
              sx={{
                fontSize: 18,
                fontWeight: 'normal',
                textTransform: 'uppercase',
              }}
              onClick={onConfirm}
            >
              Согласовать
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={openErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="span">
            {headerErrorModal}
          </Typography>
          {!!Array.isArray(messageErrorModal) && (
            <Stack spacing={1} sx={{ mt: 2 }} maxHeight="80%" overflow="auto">
              {messageErrorModal.map(({ name, data }, currentIndex) => (
                <Stack key={currentIndex}>
                  <Typography id="modal-modal-description" fontSize={18}>
                    {name}
                    {data ? ':' : ''}
                  </Typography>
                  {data && (
                    <Stack
                      direction="row"
                      spacing={0.5}
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      {data.map(({ value, color }, index) => (
                        <Typography
                          key={index}
                          fontSize={18}
                          color={color}
                          display="inline"
                        >
                          {value}
                        </Typography>
                      ))}
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
          <Stack
            sx={{ mt: 4 }}
            spacing={2}
            direction="row"
            justifyContent="space-between"
          >
            <Box flexGrow={1}>
              <Button
                size="large"
                sx={{
                  width: '100%',
                  fontSize: 18,
                  flexGrow: 1,
                  fontWeight: 'normal',
                  textTransform: 'uppercase',
                }}
                onClick={onCloseErrorModal}
              >
                Отмена
              </Button>
            </Box>

            <Button
              size="large"
              variant="contained"
              color="primary"
              sx={{
                fontSize: 18,
                fontWeight: 'normal',
                textTransform: 'uppercase',
              }}
              onClick={onOkError}
            >
              Согласовать
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Notification
        open={openErrorNotify}
        message="Ошибка согласования"
        icon={<ErrorIcon />}
        onClose={() => setOpenErrorNotify(false)}
      />
    </ApproveSomeDocumentsContext.Provider>
  )
}
